<template>
  <section>
    <div
      class="order__modal"
      :class="loadingButton ? `click__none` : ``"
      ref="newOrderModal"
    >
      <div
        class="order__modal--block"
        :class="closeModal ? `slide__right` : `slide__left`"
      >
        <div @click="closeModalOrder" class="order__modal--close">
          <div>
            <img src="../../assets/images/close.svg" alt="Закрыть" />
          </div>
        </div>
        <section class="modal--data" ref="modal">
          <div
            v-if="leftViewTab"
            @mouseover="leftViewTabHandler"
            @mouseleave="clearIntervalHandler"
            class="modal--data--arrblock arrblock__left"
          >
            <img src="../../assets/images/arrow_left.png" alt="Влево" />
          </div>
          <div
            v-if="rightViewTab"
            @mouseover="rightViewTabHandler"
            @mouseleave="clearIntervalHandler"
            class="modal--data--arrblock arrblock__right"
          >
            <img src="../../assets/images/arrow_right.png" alt="Вправо" />
          </div>
          <div class="modal__data--header">
            <h1>Новый заказ</h1>
          </div>
          <div class="modal__data--link">
            <!-- <div @click="addNewRequest">Загрузить свою заявку</div> -->
          </div>
          <div class="modal__data--block">
            <div class="modal__data--main">
              <div class="modal__body">
                <div class="modal__body--element">
                  <div style="width: 384px">
                    <div class="modal__body--form">
                      <div class="modal__form">
                        <!-- <form-input
                          v-model="createDate"
                          type="date"
                          label="Дата подачи"
                          width="87"
                        ></form-input>
                        <img
                          class="input__custom--icon"
                          src="../../assets/images/modal-order/calendar.svg"
                          alt="Дата подачи"
                        /> -->
                        <label class="date__picker--label">Дата подачи</label>
                        <date-picker
                          v-model="createDate"
                          @change="$emit(`changeCreateDate`, createDate)"
                          :shortcuts="shortcuts"
                          type="date"
                          valueType="timestamp"
                          :disabled-date="disabledBeforeToday"
                          format="DD-MM-YYYY"
                          placeholder="дд:мм:гггг"
                          style="width: 176px"
                          required
                        ></date-picker>
                      </div>
                      <div class="modal__form">
                        <form-input
                          v-model="createTime"
                          type="time"
                          label="Время подачи"
                          width="95"
                        ></form-input>
                        <img
                          class="input__custom--icon"
                          src="../../assets/images/modal-order/time.svg"
                          alt="Время подачи"
                        />
                        <!-- <label class="date__picker--label">Время подачи</label>
                        <date-picker
                          v-model="createTime"
                          @change="$emit(`changeCreateTime`, createTime)"
                          format="HH:mm"
                          value-type="format"
                          type="time"
                          placeholder="--:--"
                          style="width: 176px"
                          required
                        ></date-picker> -->
                      </div>
                    </div>
                    <div v-if="newOrderType !== 2" class="modal__body--form">
                      <form-select
                        label="Тоннаж"
                        v-model="tonnage"
                        @select="changeTonnage"
                        :options="tonnageArray"
                        required
                      ></form-select>
                      <form-select
                        label="Тип ТС"
                        v-model="boxType"
                        @select="changeBoxType"
                        :options="boxTypeArray"
                        required
                      ></form-select>
                    </div>
                    <div class="modal__form" v-if="userData.useRequestNumber">
                      <form-input
                        v-model="requestNumber"
                        label="Номер заявки"
                        placeholder="Введите Ваш номер заявки"
                        style="width: 175px"
                        :styles="'height: 31px'"
                      ></form-input>
                    </div>
                  </div>
                </div>
                <div v-if="newOrderType !== 2" style="margin-top: 20px">
                  <div
                    style="
                      display: grid;
                      grid-template-columns: 170px 170px 170px 170px;
                      grid-template-rows: 30px 30px 30px;
                    "
                  >
                    <!-- <div
                      v-for="(option, i) in getOptionDopList"
                      :key="i"
                      style="grid-column: span 1; grid-row: span 1"
                    >
                      <form-checkbox
                        @change="changeDopOption(option[0])"
                        v-model="dopOptionArray"
                        :checked="orderOption[option[0]]"
                        :value="option[0]"
                        :label="option[1]"
                        :required="false"
                      ></form-checkbox>
                    </div> -->
                  </div>
                </div>
                <div class="modal__body--header">
                  <h4 v-if="newOrderType === 1">Маршрут</h4>
                  <h4 v-else>Места работы</h4>
                </div>
                <!-- Вывод списка маршрутов -->
                <div
                  class="modal__table"
                  style="margin-bottom: 32px; margin-left: -16px"
                >
                  <div class="modal__table--route">
                    <div
                      class="modal__table--item"
                      v-for="(route, idx) in routeArray"
                      :key="idx"
                      @click="addRouteActive(idx)"
                    >
                      <div class="route__move">
                        <img src="../../assets/images/modal-order/move.svg" />
                      </div>
                      <div class="route__index">
                        {{ idx + 1 }}
                      </div>
                      <div class="route__type" @click="openRoute(idx)">
                        <div
                          :class="route.open ? `active` : ``"
                          class="route__type--block"
                        >
                          <img
                            v-if="route.type === `Загрузка`"
                            class="route__type--img"
                            src="../../assets/images/truck_up.svg"
                            alt="Truck Up"
                          />
                          <img
                            v-if="route.type === `Выгрузка`"
                            class="route__type--img"
                            src="../../assets/images/truck_down.svg"
                            alt="Truck Down"
                          />
                          {{ route.type }}
                          <span v-if="!route.open" class="close"></span>
                          <span v-else class="open"></span>
                        </div>
                        <div
                          :class="route.open ? `active` : ``"
                          class="route__type--hidden"
                        >
                          <div @click="changeTypeRoute(idx, `Загрузка`)">
                            <img
                              src="../../assets/images/truck_up.svg"
                              alt="Truck Up"
                            />Загрузка
                          </div>
                          <div @click="changeTypeRoute(idx, `Выгрузка`)">
                            <img
                              src="../../assets/images/truck_down.svg"
                              alt="Truck Down"
                            />Выгрузка
                          </div>
                        </div>
                      </div>
                      <div class="route__address--new">
                        <img src="../../assets/images/route.svg" alt="Адрес" />
                        <form-input
                          type="text"
                          @input="changeAddressRoute"
                          placeholder="Адрес точки маршрута"
                          :value="route.address"
                          :autoadd="false"
                        ></form-input>
                      </div>
                      <div class="route__comment">
                        <form-input
                          type="text"
                          @input="changeCommentRoute"
                          label="Комментарий (Ф.И.О., телефон, примечание)"
                          placeholder="Введите текст"
                          :value="route.comment"
                        ></form-input>
                      </div>
                      <div @click="deleteRoute(idx)" class="route__delete">
                        <img
                          src="../../assets/images/close.svg"
                          alt="Удалить"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal__route">
                  <div @click="addNewRoute" class="modal__route--add">
                    <img
                      src="../../assets/images/modal-order/plus-circle.svg"
                      alt="Добавить"
                    />
                    Добавить точку
                    <span v-if="newOrderType === 1">маршрута</span>
                  </div>
                  <div @click="addNewRouteArray" class="modal__route--add">
                    Загрузить списком
                  </div>
                </div>
                <div style="height: 88px; width: 368px">
                  <form-textarea
                    label="Коментарий к заказу"
                    v-model="commentOrder"
                    @textarea="changeOrderComment"
                    placeholder="Текст комментария"
                    width="140"
                    styles="height: 88px; width: 368px"
                  ></form-textarea>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="modal--footer">
          <div style="position: relative">
            <!-- <div v-if="loadingButton" class="loading__button">
              <img
                src="../../assets/images/loading__button.svg"
                alt="Загрузка"
              />
            </div> -->
            <Lottie
              v-if="loadingButton"
              :options="defaultOptions"
              :width="30"
              :height="30"
              class="loading__button"
            />
            <form-button
              :disabled="!checkReqyiredFields"
              class="modal--footer--btn button__yellow"
              @click="addNewOrder"
              title="СОЗДАТЬ ЗАКАЗ"
            >
            </form-button>
          </div>
        </section>
      </div>
    </div>
    <div v-if="openSecondModal" class="order__modal">
      <div
        class="modal__second"
        :class="openSecondModal ? `slide__bottom` : `slide__top`"
        ref="newSecondModal"
      >
        <div class="modal__second--big">
          <div class="modal__second--close">
            <img
              @click="closeSecondModal"
              src="../../assets/images/close.svg"
              alt="Закрыть"
            />
          </div>
          <section v-if="modalAddRoute">
            <h1>
              Вставьте список адресов, <br />разместите каждый адрес с новой
              строки
            </h1>
            <form-textarea
              v-model="routesList"
              styles="height: 150px; width: 480px"
              placeholder="Староватутинский проезд, 12с3
Ленская улица, 7
Извилистый проезд, 9с1
Изумрудная улица, 18
улица Лётчика Бабушкина, 6"
              @textarea="getNewRoutesList"
            ></form-textarea>
            <div
              @click="addNewRouteArrayHandler"
              class="modal__second--btn button__yellow"
            >
              ЗАГРУЗИТЬ
            </div>
          </section>
          <section v-if="modalAddTemplate">
            <h1>Придумайте название шаблону заказа</h1>
            <div style="width: 500px; margin: 0 auto">
              <form-input
                v-model="newTemplate"
                label="Название шаблона"
                placeholder="Введите текст"
                @input="getNewTemplate"
              ></form-input>
            </div>
            <div
              @click="addNewTemplateHandler"
              class="modal__second--btn button__yellow"
            >
              СОХРАНИТЬ
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Компоненты формы
// import FormCheckbox from "../form/form-checkbox";
// Данные по чекбоксам
import { orderOptionsData } from "../../data/order-options";
// Данные по маршрутам
// import { routeCreate } from "../../data/order-route-create";
// Animation
import Lottie from "vue-lottie";
import animationData from "../../assets/animations/circle-loader.js";
// Store
import { mapActions, mapState } from "vuex";
// Mixins
import orderFunctions from "@/mixins/order-functions";

export default {
  name: "NewOrder",
  props: {
    newOrderType: {
      type: Number,
      default: 1
    },
    currentOrderData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
   
    Lottie
  },
  mixins: [orderFunctions],
  data() {
    return {
      closeModal: false,
      optionDopList: orderOptionsData,
      // createDate: this.currentOrderData.formatedDate
      //   ? this.currentOrderData.formatedDate
      //   : "",
      // createTime: this.currentOrderData.timeToView
      //   ? this.currentOrderData.timeToView
      //   : "",
      tonnage: this.currentOrderData.tonnage
        ? this.currentOrderData.tonnage
        : "",
      boxType: this.currentOrderData.boxType
        ? this.currentOrderData.boxType
        : "",
      requestNumber: this.currentOrderData.requestNumber
        ? this.currentOrderData.requestNumber
        : "",
      commentOrder: this.currentOrderData.orderNote
        ? this.currentOrderData.orderNote
        : "",
      orderOption: this.currentOrderData.orderOption
        ? this.currentOrderData.orderOption
        : [],
      dopOptionArray: this.currentOrderData.dopOptions
        ? this.currentOrderData.dopOptions
        : [],
      // hours: Array.from({ length: 10 }).map((_, i) => i + 8),
      routeType: [
        {
          name: "Загрузка",
          value: "Загрузка"
        },
        {
          name: "Выгрузка",
          value: "Выгрузка"
        }
      ],
      routeArray: [],
      routeArrayFirst: {
        address: "",
        arrivalDate: null,
        arrivalTime: null,
        comment: null,
        departedDate: null,
        departedTime: null,
        type: "Загрузка",
        open: false
      },
      routeArraySecond: {
        address: "",
        arrivalDate: null,
        arrivalTime: null,
        comment: null,
        departedDate: null,
        departedTime: null,
        type: "Выгрузка",
        open: false
      },
      routeActive: "",
      openSecondModal: false,
      modalAddRoute: false,
      routesList: "",
      modalAddTemplate: false,
      newTemplate: "",
      leftViewTab: false,
      rightViewTab: false,
      pageWidth: 0,
      scrollWidth: 0,
      resultWidth: 0,
      leftScroll: 0,
      rightScroll: 0,
      loadingButton: false,
      defaultOptions: { animationData: animationData },
      animationSpeed: 1
    };
  },
  created() {
    document.addEventListener("click", this.dropModalHandler);
    window.addEventListener("resize", this.getElement);
    const newFirstRout = Object.assign({}, this.routeArrayFirst),
      newLastRout = Object.assign({}, this.routeArraySecond);
    newFirstRout.address = this.userData.firstAddress;
    this.currentOrderData.routeArray
      ? (this.routeArray = this.currentOrderData.routeArray)
      : (this.routeArray = [newFirstRout, newLastRout]);
  },
  mounted() {
    this.getElement();
    this.$refs.modal.addEventListener("scroll", this.getElementScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getElement);
    this.$refs.modal.removeEventListener("scroll", this.getElementScroll);
    this.clearIntervalHandler();
  },
  destroyed() {
    document.removeEventListener("click", this.dropModalHandler);
  },
  computed: {
    ...mapState(["serverPath", "token", "activePageView", "userData"]),
    getOptionDopList() {
      return Object.entries(this.optionDopList);
    },
    routeArrayToLoad() {
      return this.routeArray.map((item, index) => {
        item.position = index;
        return item;
      });
    },
    optionArrayToLoad() {
      return this.dopOptionArray.map((item) => {
        return { [item]: 1 };
      });
    }
  },
  methods: {
    ...mapActions([
      "CHANGE_ACTIVE_PAGE",
      "REFRESH_TOKEN_HANDLER",
      "GET_ORDERS",
      "ADD_ORDER"
    ]),
    // Получаем правильный массив выбранных чекбоксов
    getTrueNameChecked(value) {
      const temp = [];
      for (let key in value) {
        console.log(key);
        if (value[key]) {
          temp.push(key);
        }
      }
    },
    // Отправляем на сервер данные по новому заказу
    async postAddOrderToServer(data) {
      await this.axios
        .post("/api/client/order/create", data, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          // console.log(response.data.response);
          this.ADD_ORDER(response.data.response).then(() => {
            this.loadingButton = false;
            // this.routeCreate = [];
            this.$emit("getUpdateOrder", response.data.response.orderId);
            this.closeModalOrder();
            this.$emit("getNewOrder", response.data.response.orderId);
          });
          if (this.activePageView !== "orders") {
            this.CHANGE_ACTIVE_PAGE("orders");
            this.$router.push("/");
          }
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.postAddOrderToServer();
            });
          } else {
            console.error(
              "Ошибка в получении postAddOrderToServer" + e.message
            );
          }
        });
    },
    // Закрываем окно
    closeModalOrder() {
      setTimeout(() => {
        this.$emit("closeModalOrderHandler", false);
      }, 400);
      this.closeModal = true;
    },
    // Открываем опции селектов
    openRoute(index) {
      this.routeArray[index].open = !this.routeArray[index].open;
    },
    // МАРШРУТ Изменение опции селектов
    changeTypeRoute(index, value) {
      this.routeArray[index].type = value;
    },
    // МАРШРУТ активный
    addRouteActive(index) {
      this.routeActive = +index;
    },
    // МАРШРУТ Изменение адреса
    changeAddressRoute(value) {
      this.routeArray[this.routeActive].address = value;
    },
    // МАРШРУТ Изменение комментария
    changeCommentRoute(value) {
      this.routeArray[this.routeActive].comment = value;
    },
    // Изменение опций чекбоксов
    changeDopOption(value) {
      if (this.dopOptionArray.includes(value)) {
        const index = this.dopOptionArray.findIndex((item) => item === value);
        this.dopOptionArray.splice(index, 1);
      } else {
        this.dopOptionArray.push(value);
      }
    },
    // Добавление нового маршрута
    addNewRoute() {
      if (this.routeArray.length > 0) {
        const newLastRout = Object.assign({}, this.routeArraySecond);
        this.routeArray.push(newLastRout);
      } else {
        const newFirstRout = Object.assign({}, this.routeArrayFirst);
        this.routeArray.push(newFirstRout);
      }
    },
    // Удалить точку маршрута
    deleteRoute(index) {
      this.routeArray.splice(index, 1);
    },
    // Закрыть модальное окно
    closeSecondModal() {
      this.openSecondModal = false;
      this.modalAddRoute = false;
      this.modalAddTemplate = false;
      // this.modalAddRequest = false;
      document.removeEventListener("click", this.dropModalSecondHandler);
    },
    // Открываем модальное окно для загрузки списка маршрутов
    addNewRouteArray() {
      this.openSecondModal = true;
      document.addEventListener("click", this.dropModalSecondHandler);
      this.modalAddRoute = true;
    },
    // Получаем список новых маршрутов
    getNewRoutesList(value) {
      this.routesList = value;
    },
    // Открываем модальное окно для добавления нового шаблона
    addNewTemplate() {
      this.openSecondModal = true;
      document.addEventListener("click", this.dropModalSecondHandler);
      this.modalAddTemplate = true;
    },
    // Получаем название нового шаблона
    getNewTemplate(value) {
      this.newTemplate = value;
    },
    // Сохранить загруженные маршруты
    addNewRouteArrayHandler() {
      this.closeSecondModal();
      const newArray = [],
        oldArray = this.routeArray;
      this.routesList.split("\n").forEach((item) => {
        newArray.push({
          address: item,
          arrivalDate: null,
          arrivalTime: null,
          comment: null,
          departedDate: null,
          departedTime: null,
          type: "Выгрузка",
          open: false
        });
      });
      this.routeArray = [...oldArray, ...newArray];
      this.routesList = "";
    },
    // Добавляем новый шаблон
    addNewTemplateHandler() {
      this.closeSecondModal();
    },
    // Добавляем новую заявку
    // addNewRequest() {
    //   this.openSecondModal = true;
    //   document.addEventListener("click", this.dropModalSecondHandler);
    //   this.modalAddRequest = true;
    // },
    // Открываем форму загрузки заявки
    // openFormAddRequest(e) {
    //   console.log(e.target.value);
    // },
    // Добавляем новый заказа
    addNewOrder() {
      this.loadingButton = true;
      let newOrderJSON = {
        createDate: this.createDateToServer,
        createTime: this.createTime,
        tonnage: this.tonnage,
        boxType: this.boxType,
        requestNumber: this.requestNumber,
        orderType: this.newOrderType,
        note: this.commentOrder,
        routeArray: this.routeArrayToLoad
      };
      this.optionArrayToLoad.forEach((item) => {
        newOrderJSON = { ...newOrderJSON, ...item };
      });
      this.postAddOrderToServer(JSON.stringify(newOrderJSON));
      // console.log(JSON.stringify(newOrderJSON));
    },
    // Клик не на элементе модального окна
    dropModalHandler(e) {
      let el = this.$refs.newOrderModal;
      let target = e.target;
      if (el === target && el.contains(target)) {
        this.closeModalOrder();
      }
    },
    // Клик не по элементу второго окна
    dropModalSecondHandler(e) {
      let el = this.$refs.newSecondModal;
      let target = e.target;
      if (el === target && el.contains(target)) {
        this.closeSecondModal();
      }
    },
    // ПРОКРУТКА
    // Ширина элемента таблицы
    getWidthElement() {
      this.elemWidth = +this.$refs.modal.clientWidth;
      this.allWidth = +this.$refs.modal.scrollWidth;
      this.resultWidth = this.allWidth - this.elemWidth;
      if (this.allWidth !== this.elemWidth) {
        this.rightViewTab = true;
        this.leftViewTab = false;
      } else {
        this.leftViewTab = false;
        this.rightViewTab = false;
      }
    },
    // Получение всех размеров элемента
    getElement() {
      this.getWidthElement();
    },
    // Прокрутка по скролу добавление и удаление кнопок
    getElementScroll() {
      if (
        this.$refs.modal.scrollLeft === 0 &&
        this.elemWidth !== this.allWidth
      ) {
        this.clearIntervalHandler();
        this.leftViewTab = false;
        this.rightViewTab = true;
      }
      if (
        this.$refs.modal.scrollLeft === this.resultWidth &&
        this.elemWidth !== this.allWidth
      ) {
        this.clearIntervalHandler();
        this.leftViewTab = true;
        this.rightViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок влево
    leftViewTabHandler() {
      this.clearIntervalHandler();
      this.leftScroll = setInterval(() => {
        this.addSlideTable(-20, 200);
      }, 100);
      this.rightViewTab = true;
      if (this.$refs.modal.scrollLeft === 0) {
        this.clearIntervalHandler();
        this.leftViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок вправо
    rightViewTabHandler() {
      this.clearIntervalHandler();
      this.rightScroll = setInterval(() => {
        this.addSlideTable(20, 200);
      }, 100);
      this.leftViewTab = true;
      if (this.$refs.modal.scrollLeft === this.resultWidth) {
        this.clearIntervalHandler();
        this.rightViewTab = false;
      }
    },
    // Прокрутка с помощью кнопок влево и вправо
    addSlideTable(step, period) {
      const startTime = Date.now();
      const render = () => {
        const dt = Date.now() - startTime;
        if (dt < period) {
          // console.log((step * dt) / period);
          this.$refs.modal.scrollLeft =
            this.$refs.modal.scrollLeft + (step * dt) / period;
          requestAnimationFrame(render);
        }
      };
      requestAnimationFrame(render);
    },
    // Убираем интервал
    clearIntervalHandler() {
      clearInterval(this.rightScroll);
      clearInterval(this.leftScroll);
    }
  }
};
</script>

<style lang="scss">
.modal__form input.mx-input {
  cursor: pointer;
  width: 176px !important;
}

.date__picker--label {
  background-color: $color-white;
  font-size: 12px;
  font-weight: 500;
  left: 10px;
  padding: 0 5px;
  position: absolute;
  top: -7px;
  z-index: 3;
}
</style>
